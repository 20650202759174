import Moment from "react-moment";
import { getZmanimJson } from "kosher-zmanim";
import moment from "moment";
const MinyanStatus = (props) => {
    const { date, eventName, isHaliday, sureCount, onlyIfCount, zmanInfo, hasMikvah, shulOpen, mikvahOpen, mussafReservedBy, baalKorahInfo } = props;
    const hasMinyan = sureCount + onlyIfCount >= 10;
    const motzei = getZmanimJson(zmanInfo).BasicZmanim.Tzais72;
    const candleLighting = moment(getZmanimJson({ ...zmanInfo, date: new Date(moment(new Date(date)).subtract(1, "day")) }).BasicZmanim.CandleLighting).add(3, "minutes");
    return (<div
        className="md:col-span-4 md:col-end-13 col-span-12 flex items-center"
        style={{ backgroundImage: "url('/images/zmanim_bg.png')", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}
    >
        <div className="bg-center bg-cover px-7 py-20 text-white">
            <p className="uppercase text-3xl mb-2">Upcoming</p>
            <Moment
                className="text-dependable-gold tracking-wider text-xl"
                format="dddd MMM Do, YYYY"
                date={new Date(date)}
            />
            <p className="tracking-wider text-xl font-semibold ">{eventName}</p>
            <div className="my-12"></div>
            {!hasMinyan && (
                <>
                    <p className="text-lg mb-2">
                        <span className="text-dependable-gold font-semibold inline-block mr-2">
                            People coming: <span className="text-white">{sureCount}</span>
                        </span>
                    </p>
                    <p className="text-lg mb-2">
                        <span className="text-dependable-gold font-semibold inline-block mr-2">
                            People coming if there is minyan: <span className="text-white">{onlyIfCount}</span>
                        </span>
                    </p>
                </>
            )}
            {hasMinyan && (
                <p className="text-lg mb-2">
                    <span className="text-dependable-gold font-semibold inline-block mr-2">
                        People coming: <span className="text-white">{sureCount + onlyIfCount}</span>
                    </span>
                </p>
            )}
            <p className="text-lg mb-2">
                <span className="text-dependable-gold font-semibold inline-block mr-2">
                    Maftir & Mussaf: <span className="text-white">{mussafReservedBy ?
                        `Reserved by ${mussafReservedBy}` : "Not Reserved"}</span>
                </span>
            </p>
            <p className="text-lg mb-2">
                <span className="text-dependable-gold font-semibold inline-block mr-2">
                    Baal Korah: <span className="text-white">{(!baalKorahInfo['1-4'] && !baalKorahInfo['5-end']
                        && !baalKorahInfo['1st'] && !baalKorahInfo['2nd']) ?
                        'No Baal Korah' : (baalKorahInfo['1-4'] && (baalKorahInfo['1-4'] === baalKorahInfo['5-end'])) ?
                            baalKorahInfo['1-4'] : ((baalKorahInfo['1-4']|| baalKorahInfo['5-end'] ) ?
                                (((baalKorahInfo['1-4']) ?
                                    baalKorahInfo['1-4'] + " till Revi'i" : '') + ((baalKorahInfo['5-end']) ?
                                        (baalKorahInfo['1-4']) ?
                                            ', ' + baalKorahInfo['5-end'] + ' from Chamishi' : baalKorahInfo['5-end'] + ' from Chamishi' : '')) :
                                (((baalKorahInfo['1st']) ?
                                     baalKorahInfo['1st'] + " 1'st Half" : '') + ((baalKorahInfo['2nd']) ?
                                        ((baalKorahInfo['1st']) ?
                                            ', ' + baalKorahInfo['2nd'] + ' 2\'nd Half' : baalKorahInfo['2nd'] + ' 2\'nd Half') : '')))}</span>
                </span>
            </p>
            <p className="text-lg mb-2">
                <span className="text-dependable-gold font-semibold inline-block mr-2">
                    Candle Lighting: <Moment className="text-white" format="h:mm a" date={candleLighting} />
                </span>
            </p>
            <p className="text-lg mb-2">
                <span className="text-dependable-gold font-semibold inline-block mr-2">
                    Motzei {isHaliday ? "Yom Tov" : "Shabbos"}:{" "}
                    <Moment className="text-white" format="h:mm a" date={motzei} />
                </span>
            </p>
            <p className="text-lg mb-2">
                <span className="text-dependable-gold font-semibold inline-block mr-2">
                    Shul Open: <span className="text-white">{shulOpen ? "Yes" : "No"}</span>
                </span>
            </p>
            {(hasMikvah) && (
                <p className="text-lg">
                    <span className="text-dependable-gold font-semibold inline-block mr-2">
                        Mikvah Open: <span className="text-white">{mikvahOpen ? "Yes" : "No"}</span>
                    </span>
                </p>
            )}
        </div>
    </div>);
}
export default MinyanStatus;